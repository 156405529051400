import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import greyEStop from '../../assets/images/grey_e-stop_engaged.png';
import sensingEgdes from '../../assets/images/sensing-edges-engaged.png';
import lockout from '../../assets/images/lockout-engaged.png';
import estop from '../../assets/images/e-stop-engaged.png';
import swEstop from '../../assets/images/sw-estop-engaged.png';
import safetyStop from '../../assets/images/safety-stop-engaged.png';
import '../../App.css';
import { ESTOP_TYPES } from '../../utils/constants';

export const EStopIndicator = ({
  eStopEngaged,
  videoStream,
  width,
  height,
  marginLeft,
  marginTop,
  marginRight,
  marginBottom,
  isMultiRobots,
  isMultiRobotAudit,
  eStopType
}) => {
  const useStyles = makeStyles((theme) => ({
    image: {
      position: 'relative',
      bottom: '0px',
      width,
      height,
      marginLeft,
      marginTop,
      marginRight,
      marginBottom
    },
    multiRobotsEStopIndicator: {
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%'
    },
    multiRobotsAuditEStopIndicator: {
      maxWidth: '100%',
      maxHeight: '85%'
    },
    multiRobotsEstopContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));

  const classes = useStyles();
  const [imageSource, setImageSource] = useState();

  useEffect(() => {
    if (eStopType === ESTOP_TYPES.PHYSICAL_ESTOP) {
      setImageSource(estop);
    }
    if (eStopType === ESTOP_TYPES.SW_ESTOP) {
      setImageSource(swEstop);
    }
    if (eStopType === ESTOP_TYPES.CONTROL_LOCKOUT) {
      setImageSource(lockout);
    }
    if (eStopType === ESTOP_TYPES.SENSING_EDGES) {
      setImageSource(sensingEgdes);
    }
    if (eStopType === ESTOP_TYPES.SAFETY_STOP) {
      setImageSource(safetyStop);
    }
  }, [eStopType]);

  if (videoStream) {
    if (eStopEngaged) {
      if (isMultiRobots && !isMultiRobotAudit) {
        return (
          <Box className={classes.multiRobotsEstopContainer} id="estop">
            <img src={imageSource} alt="E-Stop Sign" className={classes.multiRobotsEStopIndicator} />
          </Box>
        );
      } else if (isMultiRobots && isMultiRobotAudit) {
        return (
          <Box className={classes.multiRobotsEstopContainer} id="estop">
            <img src={imageSource} alt="E-Stop Sign" className={classes.multiRobotsAuditEStopIndicator} />
          </Box>
        );
      } else if (isMultiRobots && isMultiRobotAudit) {
        return (
          <Box className={classes.multiRobotsEstopContainer} id="estop">
            <img src={redEStop} alt="E-Stop Sign" className={classes.multiRobotsAuditEStopIndicator} />
          </Box>
        );
      } else {
        return (
          <div id="estop">
            <img src={imageSource} className={classes.image} style={{ top: '140px', position: 'fixed', left: '18%' }} />
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  } else {
    useEffect(() => {
      var image = document.getElementById('E-Stop');

      if (eStopEngaged) {
        image.src = imageSource;
      } else {
        image.src = greyEStop;
      }
    });

    return (
      <div>
        <img src={imageSource} id="E-Stop" className={classes.image} />
      </div>
    );
  }
};
