import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, IconButton, Box, Typography } from '@material-ui/core';
import { HighlightOff, Refresh, SwitchCamera } from '@material-ui/icons';
import { useStores } from '../../../store/root/root.store';
import VideoStreamMultiPageView from '../video-stream-multi-page-view.component';
import SolarMap from '../../maps/solar-map';
import { ChaperoneRobotConnectionService } from '../../../services/chaperone/robot-connection.service';
import { EStopIndicator } from '../e-stop-indicator';
import { formatStringForReadbility } from '../../../utils/ui.utils';
import { getSolarMapData } from '../../../services/api/robots.service';
import { ESTOP_TYPES } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  solidGreen: {
    border: '5px solid green'
  },
  '@keyframes flashYellowAnimation': { '0%, 100%': { borderColor: 'transparent' }, '50%': { borderColor: 'yellow' } },
  '@keyframes flashRedAnimation': { '0%, 100%': { borderColor: 'transparent' }, '50%': { borderColor: 'red' } },
  flashYellow: { border: '5px solid ', animation: '$flashYellowAnimation 1s infinite' },
  flashRed: { animation: '$flashRedAnimation 1s infinite' },
  mainContainerBaseTemplate: {
    background: theme.palette.grey[300],
    border: '5px solid green',
    maxHeight: '595px',
    height: '570px',
    width: '50%',
    display: 'grid',
    gridTemplateColumns: '0.7fr 1.3fr 1fr',
    gridTemplateRows: '1.2fr 0fr 1.58fr',
    gap: '0px 0px',
    gridTemplateAreas: `"details-area video-area video-area"
      "map-area video-area video-area"
      "map-area video-area video-area"`,
    position: 'relative'
  },
  mainContainer: {
    background: theme.palette.grey[300],
    maxHeight: '595px',
    height: '570px',
    width: '49%',
    display: 'grid',
    gridTemplateColumns: '0.7fr 1.3fr 1fr',
    gridTemplateRows: '1.2fr 0fr 1.8fr',
    gap: '0px 0px',
    gridTemplateAreas: `"details-area video-area video-area"
      "details-area video-area video-area"
      "details-area video-area video-area"`,
    position: 'relative',
    boxSizing: 'border-box',
    margin: '5px'
  },
  robotDetailsContainer: {
    gridArea: 'details-area',
    background: theme.palette.grey[300],
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: '5px',
    boxSizing: 'border-box',
    margin: '1px',
    border: '2px solid #302c2c',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  robotVideoContainer: {
    gridArea: 'video-area',
    position: 'relative',
    height: '100%',
    minHeight: '510px',
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #302c2c'
  },
  notificationBox: {
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
    overflowY: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '10%',
    border: '2px solid #302c2c'
  },
  customNotificationText: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'black'
  },
  robotDetailsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '50%',
    marginBottom: '1px ',
    borderBottom: '1px solid #302c2c'
  },
  robotDetailsText: {
    fontWeight: 'bold'
  },

  robotDetailsTile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    border: '3px solid #302c2c'
  }
}));

/**
 * A lightweight version of Robot Control View used for auditing robots.
 * Supervisors can monitor robot activity through video feed and various opertations data like
 * current operating block, row, and etc.
 * @param {String} robotSerialNumber Robot serial number used as unique indentifier.
 * @param {Object} cord Object containing a an array of coordinate points. These coordinates are used to render polylines on map to show operating subrows
 * @param {Boolean} swEstopAllRobots State tracker for e-stopping all operating robots within multi-robots audit officer
 * @param {Function} setSwEstopAllRobots State setter for e-stopping robots
 */
const MultiAuditOfficer = observer(({ robotSerialNumber, swEstopAllRobots, setSwEstopAllRobots }) => {
  const { MultiRobotsStore, blocksStore, subBlocksStore, chaperonePropertyStore } = useStores();
  const classes = useStyles();
  const selectedSolarSubRows = MultiRobotsStore.robots.get(robotSerialNumber)?.selectedSolarSubRows;
  const robotLat = MultiRobotsStore.robots.get(robotSerialNumber)?.lat;
  const robotLng = MultiRobotsStore.robots.get(robotSerialNumber)?.lng;
  const robotHeading = MultiRobotsStore.robots.get(robotSerialNumber)?.currentHeadingRad;
  const batteryLevel = MultiRobotsStore.robots.get(robotSerialNumber)?.batteryLevel;
  const stableLocalization = MultiRobotsStore.robots.get(robotSerialNumber)?.stableLocalization ? 'Calibrated' : 'Needs Calibration';
  const gpsFixStatus = MultiRobotsStore.robots.get(robotSerialNumber)?.gpsFixStatus;
  const robotNotificationMessage = MultiRobotsStore.robots.get(robotSerialNumber)?.notificationMessage || 'No New Notifications';
  const robotEstopState = MultiRobotsStore.robots.get(robotSerialNumber)?.estopState;
  const robotSwEstopState = MultiRobotsStore.robots.get(robotSerialNumber)?.swEstopState;
  const isRobotLockedOut = MultiRobotsStore.robots.get(robotSerialNumber)?.lockoutState !== '';
  const robotSensingEdgeState = MultiRobotsStore.robots.get(robotSerialNumber)?.sensingEdgeState;
  const [videoStreamKey, setVideoStreamKey] = useState(0);
  const [dualCameraMode, setdualCameraMode] = useState(true);
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const arePolyLinesLoaded = useRef(false);
  const [solarMapKey, setSolarMapKey] = useState(0);
  const [robotConnectionIndicator, setRobotConnectionIndicator] = useState(classes.mainContainer);
  const [eStopType, setEstopType] = useState('');
  const eStopState =
    MultiRobotsStore.robots.get(robotSerialNumber)?.estopState ||
    MultiRobotsStore.robots.get(robotSerialNumber)?.swEstopState ||
    MultiRobotsStore.robots.get(robotSerialNumber)?.sensingEdgeState;
  const robotControlMode = MultiRobotsStore.robots.get(robotSerialNumber)?.controlMode;
  let currentActiveRGName = '-';
  if (robotControlMode === 'autonomous' || robotControlMode === 'teleops') {
    currentActiveRGName = MultiRobotsStore.robots.get(robotSerialNumber)?.activeClient?.user;
  }
  const baldesIsRunning = MultiRobotsStore.robots.get(robotSerialNumber)?.bladeRunning ? 'ON' : 'OFF';
  const linearVelocityKmH = eStopState
    ? 0
    : parseFloat(((MultiRobotsStore.robots.get(robotSerialNumber)?.linearVelocity * 18) / 5).toFixed(1)) || 'N/A';
  const robotConnectionService = useRef(null);
  const robotConnection = useRef(null);
  const userName = localStorage.getItem('username');
  // Path parameters for API calls
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [selectedSubBlockId, setSelectedSubBlockId] = useState(null);
  const [selectedPathTpe, setSelectedPathType] = useState(null);
  // Path parameters for displaying path details
  const [selectedPropertyName, setSelectedPropertyName] = useState('N/A');
  const [selectedBlockName, setSelectedBlockName] = useState('N/A');
  const [selectedSubBlockName, setSelectedSubBlockName] = useState('N/A');
  const [selectedSubRowName, setSelectedSubRowName] = useState('N/A');
  const [selectedPathTypeName, setSelectedPathTypeName] = useState('N/A');

  /**
   * Fetches and sets solar map data when block, subblock, or path type are changed
   */
  const fetchAndSetMapData = async () => {
    try {
      const result = await getSolarMapData({
        regionId: selectedRegionId,
        propertyId: selectedPropertyId,
        blockId: selectedBlockId,
        subblockId: selectedSubBlockId,
        pathType: selectedPathTpe
      });
      const coordinatePoints = Object.entries(result).map(([key, points]) =>
        points.map((sample) => ({ key, lat: Number(sample.lat), lng: Number(sample.long), angle: Number(sample.angle) }))
      );
      setMapCoordinates(coordinatePoints);
      // eslint-disable-next-line no-return-assign
      setSolarMapKey((prev) => (prev += 1));
    } catch (e) {
      console.error(`Error ${e}`);
    }
  };

  /**
   * Handles updating block and subblock names if changed by remote guardians within robot-control view
   */
  const fetchAndSetPathData = async () => {
    try {
      if (selectedPropertyId && selectedBlockId && selectedSubBlockId) {
        const updatedPropertyData = await chaperonePropertyStore.getPropertyById({ id: selectedPropertyId });
        const updatedBlockData = await blocksStore.getBlockById({ id: selectedBlockId });
        const updatedSubBlockData = await subBlocksStore.getSubBlockById({ id: selectedSubBlockId });
        setSelectedPropertyName(updatedPropertyData?.results?.name);
        setSelectedBlockName(updatedBlockData?.results?.name);
        setSelectedSubBlockName(updatedSubBlockData?.results?.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handles updating map cordinates in response to changes in block id, subblock id, or pathtype
  useEffect(() => {
    if (selectedRegionId && selectedPropertyId && selectedBlockId && selectedSubBlockId && selectedPathTpe) {
      fetchAndSetMapData();
      fetchAndSetPathData();
    }
  }, [selectedRegionId, selectedPropertyId, selectedBlockId, selectedSubBlockId, selectedPathTpe]);

  // Handles robot connection and topic subscription
  useEffect(() => {
    (async () => {
      if (robotSerialNumber) {
        try {
          // On first load, initiate the robot connection
          if (robotConnectionService.current === null) {
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              // On Connect
              () => {
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) => {
                  MultiRobotsStore.updateState(robotSerialNumber, robotState);
                  const pathName = robotState?.robot_state?.navigation_state?.current_path_state?.path_name;
                  const pathType = robotState?.robot_state?.navigation_state?.current_path_state?.path_type;
                  // Monitors for changes in path paramaters and updates them accordingly
                  if (pathName) {
                    const [subrowData, pathData] = pathName.split('__');
                    const [subrowIndex, subrowDirection] = subrowData.split('_');
                    const subrowName = `${subrowIndex}_${subrowDirection}`;
                    const [regionId, propertyId, blockId, subBlockId] = pathData.split('_');
                    setSelectedRegionId(regionId);
                    setSelectedPropertyId(propertyId);
                    setSelectedBlockId(blockId);
                    setSelectedSubBlockId(subBlockId);
                    setSelectedPathType(pathType);
                    setSelectedPathTypeName(pathType);
                    setSelectedSubRowName(subrowName);
                    arePolyLinesLoaded.current = true;
                  } else {
                    // eslint-disable-next-line no-lonely-if
                    setSelectedRegionId(null);
                    setSelectedPropertyId(null);
                    setSelectedBlockId(null);
                    setSelectedSubBlockId(null);
                    setMapCoordinates([]);
                    arePolyLinesLoaded.current = false;
                  }
                });
                robotConnectionService?.current?.ros.subscribeToRobotNotification((message) => {
                  MultiRobotsStore.updateNotificationMessages(robotSerialNumber, message);
                });
                setRobotConnectionIndicator(`${classes.mainContainer} ${classes.solidGreen}`);
              },
              () => {
                // On diconnect
                if (robotConnectionService.current !== null) {
                  setRobotConnectionIndicator(`${classes.mainContainer} ${classes.flashYellow}`);
                  robotConnectionService?.current?.retryConnection();
                }
                robotConnection.current = null;
              },
              robotSerialNumber,
              userName,
              'solar_multi_robot_audit_officer'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      } else {
        // There is no selected robot
      }
    })();

    robotConnectionService?.current?.connectToRobot();

    return () => {
      if (robotConnectionService.current !== null) {
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
    };
  }, []);

  const handleSwEstopRobot = async () => {
    if (robotConnectionService.current !== null) {
      robotConnectionService?.current?.ros?.cmdSwEstop(true);
      setSwEstopAllRobots(false);
    }
  };

  useEffect(() => {
    if (swEstopAllRobots) {
      handleSwEstopRobot();
    }
  }, [swEstopAllRobots]);

  // All robots with the Multi robots audit officer page will be estopped when spacebar is pressed
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === 'Space' || e.key === ' ') {
        setSwEstopAllRobots(true);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
  }, []);

  // Clears polylines on solar map when property, block, subblock, and path type are empty
  useEffect(() => {
    if (!arePolyLinesLoaded.current) {
      setSolarMapKey((prev) => (prev += 1));
    }
  }, [arePolyLinesLoaded.current]);

  // tracks estop & lockout states for Estop indicator
  useEffect(() => {
    if (robotEstopState) {
      setEstopType(ESTOP_TYPES.PHYSICAL_ESTOP);
    }
    if (robotSwEstopState) {
      setEstopType(ESTOP_TYPES.SW_ESTOP);
    }
    if (isRobotLockedOut) {
      setEstopType(ESTOP_TYPES.CONTROL_LOCKOUT);
    }
    if (robotSensingEdgeState) {
      setEstopType(ESTOP_TYPES.SENSING_EDGES);
    }
  }, [robotEstopState, robotSwEstopState, isRobotLockedOut, robotSensingEdgeState]);

  return (
    <Box className={robotConnectionIndicator}>
      <Box className={classes.robotDetailsContainer}>
        <Box className={classes.robotDetailsBox}>
          <Box>
            <Typography variant="body1">
              {`Robot: `}
              <strong>{robotSerialNumber}</strong>
            </Typography>
            <Typography variant="body1">
              {`Property: `}
              <strong>{selectedPropertyName}</strong>
            </Typography>
            <Typography variant="body1">
              {`Block: `}
              <strong>{selectedBlockName}</strong>
            </Typography>
            <Typography variant="body1">
              {`Sub-Block: `}
              <strong>{selectedSubBlockName}</strong>
            </Typography>
            <Typography variant="body1">
              {`Current Row: `}
              <strong>{selectedSubRowName || 'None'}</strong>
            </Typography>
            <Typography variant="body1">
              {`Path Type: `}
              <strong>{formatStringForReadbility(selectedPathTypeName) || 'N/A'}</strong>
            </Typography>
            <Typography variant="body1">
              {`Battery Percentage: `}
              <strong>{batteryLevel}%</strong>
            </Typography>
            <Typography variant="body1">
              {`Control Mode: `}
              <strong style={{ color: robotControlMode === 'autonomous' ? 'green' : 'red' }}>
                {formatStringForReadbility(robotControlMode)}
              </strong>{' '}
              by <b>{currentActiveRGName}</b>
            </Typography>
            <Typography variant="body1">
              {`Blades: `}
              <strong style={{ color: baldesIsRunning === 'ON' ? 'green' : 'red' }}>{baldesIsRunning}</strong>
            </Typography>
            <Typography variant="body1">
              {`GPS Status: `}
              <strong style={{ color: gpsFixStatus === 'Fixed RTK' ? 'green' : 'red' }}>{gpsFixStatus}</strong>
            </Typography>
            <Typography variant="body1">
              {`INS Status: `}
              <strong style={{ color: stableLocalization === 'Calibrated' ? 'green' : 'red' }}>{stableLocalization}</strong>
            </Typography>
            <Typography variant="body1">
              {`Speed (Km/H): `}
              <strong>{linearVelocityKmH}</strong>
            </Typography>
          </Box>
          <Box>
            <Box style={{ margin: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <IconButton style={{ margin: '0px' }} onClick={() => MultiRobotsStore.removeRobot(robotSerialNumber)} size="small">
                {' '}
                <HighlightOff style={{ margin: '0px', color: 'red' }} />
              </IconButton>
            </Box>
            <Box style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IconButton title="Refresh Video Feed" onClick={() => setVideoStreamKey((prev) => (prev += 1))} size="small">
                <Refresh fontSize="large" />
              </IconButton>
              <IconButton
                title="Switch Camera View"
                onClick={() => {
                  setdualCameraMode(!dualCameraMode);
                  setVideoStreamKey((prev) => (prev += 1));
                }}
                size="small"
              >
                <SwitchCamera fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <SolarMap
          key={solarMapKey}
          robotLat={robotLat}
          robotLng={robotLng}
          robotHeading={robotHeading}
          googleMapData={mapCoordinates}
          selectedSubRows={selectedSolarSubRows}
          height="100%"
          className={false}
        />
      </Box>
      <Box className={classes.robotVideoContainer}>
        <Box style={{ height: '90%', maxHeight: '90%', background: 'rgba(32, 32, 32, 1.0)', border: '2px solid #302c2c' }}>
          <VideoStreamMultiPageView
            serialNumber={robotSerialNumber}
            key={videoStreamKey}
            hide={false}
            className={false}
            auditorView
            dualCameraMode={dualCameraMode}
          />
          <EStopIndicator eStopEngaged={eStopState} videoStream isMultiRobots isMultiRobotAudit eStopType={eStopType} />
        </Box>
        <Box className={classes.notificationBox}>
          <Typography className={classes.customNotificationText}>{robotNotificationMessage}</Typography>
        </Box>
      </Box>
    </Box>
  );
});

export default MultiAuditOfficer;
