import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useStores } from '../../store/root/root.store';
import { useEnvironment } from '../../environment';
import robotMapIcon from '../../assets/images/robot.png';
import { DEFAULT_PATH_TYPE, ROBOTS_MAP_ALERT_MESSAGE } from '../../utils/constants';
import { extractSubRowMeta } from '../../utils/ui.utils';
import LoadingDialog from '../dialogs/loading-dialog.dialog';
import { fetchBlocks } from '../../services/api/blocks.service';
import { fetchSubBlocks } from '../../services/api/subblocks.service';
import { getAllCompletedSubrowsInProperty, getBlockSolarMapData, getPropertySolarMapData } from '../../services/api/robots.service';
import AlertModal from '../dialogs/alert-modal';
import { getSubBlockPathTypes } from '../../services/api/subrows.service';

const useStyles = makeStyles(() => ({
  map: {
    height: '440px',
    width: '50%'
  },
  markerPosition: {
    top: '23px',
    left: 0,
    position: 'relative'
  }
}));

export const RobotsMapView = observer(({ plotSolarBlocks, plotSolarProperty, isMapReset, setIsMapReset }) => {
  const { regionsStore, blocksStore, chaperonePropertyStore, autonomyRobotStore, subBlocksStore } = useStores();
  const classes = useStyles();
  const { googleMaps } = useEnvironment();
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const block = blocksStore.getSelectedBlock()?.id;
  const subBlock = subBlocksStore.getSelectedSubBlock()?.id;
  const center = googleMaps.defaultCenter;
  const robotLocations = useRef([]);
  const polylineArray = useRef({});
  const markerArray = useRef([]);
  const mapVariable = useRef(undefined);
  const mapsVariable = useRef(undefined);
  const icon = useRef(null);
  const firstMarkerCentered = useRef(false);
  const previousSelectedRobot = useRef('');
  const currentSelectedRobot = useRef('');
  const isMountedRef = useRef(null);
  const coordinatesCache = useRef();
  const [showLoadingMapDialog, setShowLoadingMapDialog] = useState(false);
  const [selectedBlocksData, setSelectedBlocksData] = useState({});
  const [showBusyServerAlert, setShowBusyServerAlert] = useState(false);

  /**
   * Constructs an object of blocks and their subblocks and assigns it to a state variable
   * Eg, {blockID: [subblockID,subblockID,subblockID ],blockID: [subblockID,subblockID,subblockID ] }
   */
  useEffect(() => {
    const blocksObject = {};
    const fetchAndSetBlocksData = async () => {
      if (property) {
        const response = await fetchBlocks(property);
        const blocksData = response.results;
        const blockIds = blocksData.map((blockObject) => blockObject.id);
        const subBlockPromises = blockIds.map(async (blockID) => {
          const res = await fetchSubBlocks(blockID);
          const subBlocksData = res.results;
          const subBlocksIds = subBlocksData.map((sb) => parseInt(sb.id));
          const formattedBlockId = parseInt(blockID);
          blocksObject[formattedBlockId] = subBlocksIds;
        });
        await Promise.all(subBlockPromises);
        setSelectedBlocksData(blocksObject);
      }
    };
    fetchAndSetBlocksData();
  }, [property]);

  /**
   * Clears existing polylines and resets map
   */
  const clearAllPolylines = () => {
    Object.keys(polylineArray.current).forEach((key) => {
      polylineArray.current[key].forEach((polyline) => {
        polyline.setMap(null);
      });
      polylineArray.current[key] = [];
    });
  };

  /**
   * Highlights selected sub-block in blocks map
   */
  const updateBlockPolylineStyles = () => {
    if (polylineArray.current) {
      Object.values(polylineArray.current)
        .flat()
        .forEach((polyline) => {
          const isSubBlockSelected = polyline.subblockId.toString() === subBlock?.toString();
          const color = isSubBlockSelected ? (polyline.isSubRowCut ? '#ffb66a' : '#ADFF2F') : polyline.isSubRowCut ? '#d28101' : '#008000';
          polyline.setOptions({
            strokeColor: color,
            strokeOpacity: isSubBlockSelected ? 0.9 : 0.5,
            strokeWeight: isSubBlockSelected ? 4 : 3
          });
          polyline.setMap(mapVariable.current);
        });
    }
  };

  /**
   * Highlights selected block in property
   */
  const updatePropertyPolylineStyles = () => {
    if (polylineArray.current) {
      Object.values(polylineArray.current)
        .flat()
        .forEach((polyline) => {
          const isBlockSelected = polyline?.blockId?.toString() === block?.toString();
          const color = isBlockSelected ? (polyline.isSubRowCut ? '#ffb66a' : '#ADFF2F') : polyline.isSubRowCut ? '#d28101' : '#008000';
          polyline.setOptions({
            strokeColor: color,
            strokeOpacity: 0.9,
            strokeWeight: 4
          });
          polyline.setMap(mapVariable.current);
        });
    }
  };

  /**
   * Handler for viewing block map
   */
  useEffect(() => {
    const renderAllSubBlocks = async () => {
      let subrowCompletionStatus = null;

      if (region && property && selectedBlocksData) {
        const response = await getAllCompletedSubrowsInProperty(region, property, selectedBlocksData);
        subrowCompletionStatus = response;
      }
      if (plotSolarBlocks === 0) {
        return;
      }

      try {
        if ((!coordinatesCache.current || coordinatesCache.current.block !== block) && plotSolarBlocks !== 0) {
          isMountedRef.current = true;
          await subBlocksStore.getSubBlocks(block);
          const blockSubBlocksArray = subBlocksStore.getSubBlockIds() || [];
          const pathTypes = await getSubBlockPathTypes(blockSubBlocksArray[0]);
          const selectedPathTpe = pathTypes && pathTypes.includes(DEFAULT_PATH_TYPE) ? DEFAULT_PATH_TYPE : pathTypes[0];
          if (region && property && blockSubBlocksArray.length > 0) {
            const res = await getBlockSolarMapData(region, property, block, blockSubBlocksArray, selectedPathTpe);
            coordinatesCache.current = { data: res, block };
          }
        }
        if (coordinatesCache.current && coordinatesCache.current.data) {
          const newPolylines = await Promise.all(
            coordinatesCache.current.data.map(async (coord) =>
              Promise.all(
                Object.keys(coord).map(async (subrowName) => {
                  const { subblockId } = extractSubRowMeta(subrowName);
                  const subrow = coord[subrowName];
                  return new mapsVariable.current.Polyline({
                    path: subrow.map((point) => ({ lat: Number(point.lat), lng: Number(point.long) })),
                    map: mapVariable.current,
                    subblockId,
                    isSubRowCut: subrowCompletionStatus[subrowName]
                  });
                })
              )
            )
          );
          if (!polylineArray.current[block]) {
            polylineArray.current[block] = [];
          } else {
            polylineArray.current[block].forEach((polyline) => polyline.setMap(null));
            polylineArray.current[block] = [];
          }
          polylineArray.current[block] = [...polylineArray.current[block], ...newPolylines.flat()];
          updateBlockPolylineStyles(); // Update polyline style on first creation
        }
      } catch (e) {
        console.error(e);
        clearAllPolylines();
      }
    };
    renderAllSubBlocks();

    return () => {
      isMountedRef.current = false;
    };
  }, [plotSolarBlocks]);

  /**  Updates polyline style when sub-block is selected */
  useEffect(() => {
    updateBlockPolylineStyles();
  }, [subBlock]);

  /**
   * Highlights selected block
   */
  useEffect(() => {
    if (block) updatePropertyPolylineStyles();
  }, [block]);

  /**
   * Handler for viewing site map
   */
  useEffect(() => {
    const renderAllBlocks = async () => {
      if (plotSolarProperty === 0) {
        return;
      }
      if ((!region || !property) && plotSolarProperty === 0) {
        return;
      }
      if (!property) {
        return;
      }
      if (region && property && plotSolarProperty !== 0 && selectedBlocksData) {
        try {
          setShowLoadingMapDialog(true);
          isMountedRef.current = true;
          const res = await getPropertySolarMapData(region, property, DEFAULT_PATH_TYPE);
          const response = await getAllCompletedSubrowsInProperty(region, property, selectedBlocksData);
          const subrowCompletionStatus = response;

          const newPolylines = [];
          res.forEach((currentBlock) => {
            const blockPolylines = currentBlock.subBlocksData
              .map((subblock) =>
                Object.keys(subblock).map((subrowName) => {
                  const subrow = subblock[subrowName];
                  const polyLine = new mapsVariable.current.Polyline({
                    path: subrow.data.map((point) => ({ lat: Number(point.lat), lng: Number(point.long) })),
                    map: mapVariable.current,
                    subblockId: subrow.subBlockId,
                    isSubRowCut: subrowCompletionStatus[subrowName] === 'COMPLETED',
                    blockId: currentBlock.blockId
                  });
                  return polyLine;
                })
              )
              .flat();

            newPolylines.push(...blockPolylines);
            polylineArray.current[currentBlock.blockId] = blockPolylines;
          });
          if (polylineArray.current) updatePropertyPolylineStyles();
        } catch (e) {
          setShowBusyServerAlert(true);
          console.error(e);
        }
      }
      setShowLoadingMapDialog(false);
    };
    renderAllBlocks();
    return () => {
      isMountedRef.current = false;
    };
  }, [plotSolarProperty]);

  /**
   * Clears all polylines from map when user resets map and resets the value for isMapReset
   */
  useEffect(() => {
    if (isMapReset) {
      clearAllPolylines();
      setIsMapReset(false);
    }
  }, [isMapReset]);

  const renderWithinBounds = () => {
    if (!mapVariable.current) return;
    const bounds = mapVariable.current.getBounds();
    markerArray.current.forEach((marker) => {
      if (bounds.contains(marker.point.getPosition())) {
        marker.point.setVisible(true);
      } else {
        marker.point.setVisible(false);
      }
    });
  };

  const handleMarkerCenteringAndOpacity = (point, markerIndex, selectedId, selectedRobots) => {
    autonomyRobotStore.setSelectedRobot(selectedId);
    const currentRobot = autonomyRobotStore.getSelectedRobot();
    currentSelectedRobot.current = currentRobot.serial_number;

    if (currentRobot.serial_number === point.serialNumber || selectedRobots.includes(point.serialNumber)) {
      if (currentSelectedRobot.current !== previousSelectedRobot.current) {
        mapVariable.current.setZoom(18);
        const selectedMarker = markerArray.current.find((marker) => marker.serialNumber === currentRobot.serial_number);
        mapVariable.current.panTo(selectedMarker?.point?.position);
        previousSelectedRobot.current = currentRobot.serial_number;
        renderWithinBounds();
      }
    }
  };

  const renderMarkers = (robots, selectedId, selectedRobots) => {
    robotLocations.current.forEach((point, index) => {
      let markerIndex = markerArray.current.findIndex((marker) => marker.serialNumber === point.serialNumber);
      const arrayIndex = robots.findIndex((robot) => robot.serial_number === point.serialNumber);

      // does icon exist
      if (markerIndex !== -1) {
        // is the robot online
        if (arrayIndex !== -1) {
          markerArray.current[markerIndex].point.setPosition(point);
        } else {
          markerArray.current[markerIndex].point.setMap(null);
          markerArray.current.splice(markerIndex, 1);
          robotLocations.current.splice(index, 1);
        }
      } else if (arrayIndex !== -1) {
        const marker = new mapsVariable.current.Marker({
          position: point,
          label: {
            text: `${point.name} ${point.online ? '🟢' : '⚫'}`,
            color: '#f5f6f7',
            fontSize: '18px',
            fontWeight: 'bolder',
            className: classes.markerPosition
          },
          map: mapVariable.current
        });
        marker.setIcon(icon.current);
        markerArray.current.push({ serialNumber: point.serialNumber, point: marker });
        markerIndex = markerArray.current.length - 1;

        if (!firstMarkerCentered.current && point.lat !== 0 && point.lng !== 0) {
          mapVariable.current.setZoom(17);
          mapVariable.current.panTo(marker.position);
          firstMarkerCentered.current = true;
        }
      }

      handleMarkerCenteringAndOpacity(point, markerIndex, selectedId, selectedRobots);
    });
  };

  const checkLatAndLng = (latitude, longitude) => typeof latitude === 'number' && typeof longitude === 'number';

  const updateRobotLocations = () => {
    const { robots } = autonomyRobotStore;

    const selectedId = autonomyRobotStore.getSelectedRobot().id;
    const { selectedRobots } = autonomyRobotStore;

    robots.forEach(async (robot) => {
      autonomyRobotStore.setSelectedRobot(robot.id);
      const robotInfo = await autonomyRobotStore.getSelectedRobot();
      let latitude;
      let longitude;

      if (robot?.status !== 'OFFLINE' && robot?.robot_state?.location_state?.gps_fix_status === 'Fixed RTK') {
        const location = robotInfo?.robot_state?.location_state;
        latitude = location?.latitude;
        longitude = location?.longitude;
      } else {
        ({ latitude, longitude } = robotInfo);
      }

      const index = robotLocations.current.findIndex((robotLocation) => robotLocation.serialNumber === robotInfo.serial_number);

      if (checkLatAndLng(latitude, longitude)) {
        if (index !== -1) {
          robotLocations.current[index].lat = latitude;
          robotLocations.current[index].lng = longitude;
          robotLocations.current[index].online = robot?.status && robot?.status !== 'OFFLINE';
        } else {
          robotLocations.current.push({
            serialNumber: robotInfo.serial_number,
            name: robotInfo.name,
            lat: latitude,
            lng: longitude,
            online: robot?.status !== undefined && robot?.status !== 'OFFLINE'
          });
        }
      }
    });

    if (mapVariable.current !== undefined && mapsVariable.current !== undefined) {
      renderMarkers(robots, selectedId, selectedRobots);
    }
  };

  useEffect(() => {
    updateRobotLocations();

    const interval = setInterval(() => {
      updateRobotLocations();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const renderPolylines = (map, maps) => {
    mapVariable.current = map;
    mapsVariable.current = maps;
    icon.current = {
      url: robotMapIcon // url
    };
  };

  return (
    <div style={{ height: '500px' }}>
      <AlertModal
        open={showBusyServerAlert}
        title={ROBOTS_MAP_ALERT_MESSAGE.BUSY_SERVER.TITLE}
        body={ROBOTS_MAP_ALERT_MESSAGE.BUSY_SERVER.BODY}
        onClose={() => setShowBusyServerAlert(false)}
      />
      <LoadingDialog
        style={{ position: 'absolute' }}
        backdropProps={{ style: { position: 'relative' } }}
        show={showLoadingMapDialog}
        message="Loading Map"
      />
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: 'geometry'
        }}
        center={center}
        defaultZoom={18.56}
        options={{
          streetViewControl: true,
          overviewMapControl: true,
          disableDefaultUI: false,
          zoomControl: true,
          mapTypeId: 'hybrid',
          draggable: true
        }}
        onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
        onDragEnd={() => renderWithinBounds()}
        onZoomAnimationEnd={() => renderWithinBounds()}
        yesIWantToUseGoogleMapApiInternals
      />
    </div>
  );
});

export default RobotsMapView;
